@import '../../variables';

.PageIndicator {
  border-radius: 5px;
  padding: 7px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: $white;
  font-weight: bold;
  user-select: none;
  margin-bottom: 1em;

  .Numbers {
    display: flex;
    flex-direction: row;

    .Spread {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 5px;

      .fa-ellipsis-h {
        color: $dark-blue;
        opacity: 0.9;
        display: block;
        width: 20px;
      }

      .fa-angle-double-left,
      .fa-angle-double-right {
        color: $dark-blue;
        opacity: 0.9;
        display: none;
        width: 20px;
        cursor: pointer;
      }

      &:hover {
        .fa-ellipsis-h {
          display: none;
        }

        .fa-angle-double-left,
        .fa-angle-double-right {
          display: block;
        }
      }
    }

    .PageDecrement-Section,
    .PageIncrement-Section {
      display: flex;
    }

    .Number {
      margin-right: 10px;
      font-weight: 400;
      color: $dark-blue;
      cursor: pointer;

      &:hover {
        color: $dark-blue;
      }

      &.Active {
        color: $contrast-dark;
      }

      &.disabled {
        cursor: not-allowed;
        color: $contrast-dark;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .Next,
  .Previous {
    color: $dark-blue;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: $contrast-dark;
    }

    &.disabled {
      cursor: not-allowed;
      color: $contrast-dark;
    }
  }

  .Previous {
    margin-right: 10px;
  }

  .Next {
    margin-left: 10px;
  }
}
