@import '../../../variables';

.SideMenu {
  z-index: 6;
  position: absolute;
  top: 75px;
  right: -500px;
  width: 0;
  transition: 0.1s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: rgba(248, 246, 247, 0.9);
  overflow: auto;

  .Section {
    min-width: 200px;
    text-align: right;
    margin: 0 40px;

    .Entry,
    .ModalOpenBtn {
      color: $dark-blue;
      margin-top: 5px;
      font-size: 20px;
      text-transform: capitalize;
      display: block;

      &:hover {
        color: $secondary;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    &:first-child {
      margin-top: 20px;
    }

    &:last-child {
      margin-bottom: 5em;

      .Entry {
        text-transform: uppercase;
      }
    }
  }

  .Separator {
    border-bottom: 3px solid $brown;
    width: 50%;
    margin: .8em 1em;
  }

  &.Visible {
    right: 0;
    min-width: 50%;
    width: auto;
  }
}