@import '../../../../variables';

.NewTodayCarouselContainer {
  padding: 0 0.5em;
  margin: 1em 0; 
  
  .MovieView {
    margin: 0 5px;

    &:hover {
      opacity: .8;
    }
  }

  .NewTodayCarousel {
    position: relative;

    .slick-prev {
      left: 10px;
      padding: 0 20px;
    }

    .slick-next {
      right: 5px;
      padding: 0 12px;
    }

    .slick-prev,
    .slick-next {
      z-index: 2;
      width: 45px;
      height: 45px;
      border-radius: 50px;
      background-color: $primary;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      &:before {
        border-bottom: 2px solid $white;
        border-right: 2px solid $white;
        transform: rotate(-45deg);
        display: block;
        width: 12px;
        height: 12px;
        opacity: 1;
        font-size: 0;
      }
    }

    .slick-prev {
      &:before {
        transform: rotate(135deg);
      }
    }
  }
}
