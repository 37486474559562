@import '../../variables';

.SceneView {
  .PosterContainer {
    width: 30%;
  }
}

.MovieView {
  .Title {
    font-weight: 600;
  }
}

.MovieBoxCoverStillDisplay {
  font-size: 90%;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 5px;

  .PosterImage {
    width: 100%;
    height: 100%;
  }

  .PlayerContainer {
    width: 70%;
    position: relative;
    aspect-ratio: 16/9;
    overflow: hidden;

    &.FullWidth {
      width: 100%;
    }
  }

  .Duration {
    position: absolute;
    bottom: 0.8em;
    right: 0.8em;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    text-shadow: 0 0 5px #000;
  }

  .SceneImageHoverPlayer {
    height: 100%;
  }

  .MissingImage {
    color: #000;
    text-shadow: 0.3px 0.3px #fff;
    padding: 5px 10px;
    font-size: 110%;
  }
}

.MovieBoxCoverStillDisplayInfos {
  margin: .5em 0 05px;
  font-family: $font-family-metropolis;
  font-size: 95%;

  .Title {
    display: block;
    text-transform: capitalize;
    color: $primary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;

    &:hover {
      color: $secondary;
    }
  }

  .StarsContainer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .Stars {
      color: $contrast-dark;
      font-size: 70%;

      &:hover {
        color: $secondary;
      }
    }
  }
}

.BoxCoverStillDisplayImageOverlay {
  position: absolute;
  inset: 0;
  z-index: 1;
}