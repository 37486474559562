@import '../../../variables';

.PlaylistCarousel {
  background: $playlistBlue;
  color: $white;
  margin-bottom: 0.5em;
  border-radius: 0.5em 0.5em 0 0;

  .PC-ReorderLoader {
    position: absolute;
    z-index: 3;
    inset: 0;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
  }

  .PC-ListContainer {
    padding: 0 1.5em;
    min-height: 10vw;
    position: relative;

    .PC-DirectionButtons {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      border: none;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      opacity: 0.3;
      background: transparent;
      padding: 0;
      width: 25px;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }

      &.Right {
        right: 0;
        border-left: 20px solid #fff;
      }

      &.Left {
        left: 0;
        border-right: 20px solid #fff;
      }

      &.NextPageLoadingBtn {
        opacity: 0;
      }
    }

    .NextPageLoadingIcon {
      position: absolute;
      right: 5px;
      top: 40%;
      z-index: 1;
      font-size: 1.3em;
    }

    .PC-List { 
      overflow: auto;
      display: flex;

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      & {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
      }

      .PC-ListItem {
        flex-shrink: 0;
        max-width: calc(12% - 10px);
        cursor: pointer;
        padding: 1em 0.4em 0.5em;
        position: relative;
        user-select: none;

        @media (max-width: $breakpoint-tablet) {
          max-width: calc(24% - 10px);
        }

        @media (max-width: $breakpoint-mobile) {
          max-width: calc(48% - 10px);
        }

        .PC-ImageContaner {
          pointer-events: none; // for draggable effect
          position: relative;
          margin-bottom: 3px;

          .ImageComponent {
            aspect-ratio: 16 / 9;
          }

          .PC-Duration {
            font-size: 12px;
            position: absolute;
            bottom: 3px;
            right: 3px;
            background: rgba(0, 0, 0, 0.5);
            padding: 0.1em 0.3em;
          }

          .PC-NowPlaying {
            border: 3px solid $videoDetailstItems;
            border-bottom: 0;
            position: absolute;
            inset: 0;
            z-index: 1;
            text-transform: uppercase;
            display: flex;
            align-items: flex-end;

            span {
              flex: 1;
              font-size: 0.8em;
              background: $videoDetailstItems;
            }
          }
        }

        .PC-VideoTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 70%;
          opacity: 0.7;

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 10px;
          }
        }

        &:hover {
          .PC-VideoTitle {
            opacity: 1;
          }
        }
      }
    }
  }

  .PC-AccordionOptions {
    background-color: $playlistDarkBlue;
    color: $white;
    position: relative;
    border-bottom: 1px solid $videoDetailstItems;

    @media (min-width: $breakpoint-tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0.5em 0.5em 0 0;
    }

    .PC-CurrentPlaylist {
      padding: 0 1em;

      @media (max-width: $breakpoint-tablet) {
        padding: 0.5em;
        background-color: $white;
        color: $playlistBlue;
        font-weight: 600;
      }
    }

    .PC-Total {
      color: $videoDetailstItems;
      font-size: 80%;
      margin-left: 0.5em;
    }

    .PC-IconsContainer {
      @media (max-width: $breakpoint-tablet) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .PC-Icons {
        display: flex;
        align-items: center;

        > .fa {
          font-size: 0.9em;
          margin: 0 0.6em 0 0;
          cursor: pointer;

          @media (max-width: $breakpoint-tablet) {
            margin: 0 1.2em 0 0;
          }

          &:hover:not(.Caret) {
            color: $videoDetailstItems;
          }
        }

        .Caret {
          margin: 0;
          background: $videoDetailstItems;
          font-size: 1.8em;
          padding: 0.1em 0.2em;
          vertical-align: bottom;

          @media (min-width: $breakpoint-tablet) {
            border-radius: 0 0.3em 0 0;
            font-size: 1.6em;
          }
        }

        .PC-Popup {
          display: flex;

          @media (min-width: $breakpoint-tablet) {
            .PlaylistsPlayerPopup {
              bottom: unset;
              top: 100%;
              right: 0;

              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
