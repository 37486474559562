@import '../../variables';

.AlphabeticView {
  padding: 10px 30px;

  .AlphabeticView-inner {
    .Group {
      margin-bottom: 2em;
    }

    .Letter {
      margin: 0 0 12px;
      text-transform: uppercase;
      color: $dark-blue;
      font-weight: bold;
    }

    .ItemText {
      color: $dark-blue;
      display: block;

      &:hover {
        color: $brown;
      }
    }
  }

  .AlphabeticView-inner,
  .AlphabeticViewLoadingSkeleton {
    column-count: 4;

    @media (max-width: $breakpoint-tablet) {
      column-count: 3;
    }

    @media (max-width: $breakpoint-mobile) {
      column-count: 1;
    }
  }
}
