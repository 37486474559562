@import '../../../variables';

.TwelveDaysOfXXXmas {
  font-family: $font-family-metropolis;
  text-align: center;

  .FullPlayer {

    .HeartButton,
    .PlusButton {
      display: none;
    }
  }

  .TW-Content {
    padding: 0 .3em;

    @media(min-width: $breakpoint-tablet) {
      padding: 0 1.5em;
    }

    .PlayerLoader {
      height: 100%;
      background-color: #000;
    }

    .FullPlayer,
    .PlayerLoader {
      aspect-ratio: 16 / 9;
    }

    .TW-Description {
      margin-bottom: 1em;
      line-height: 1.1;

      &>* {
        margin: 0 0 .2em;
      }

      h3 {
        font-size: 100%;
        color: $secondary;
      }

      p {
        font-weight: 500;
      }
    }

    .TW-Title {
      margin: .5em 0;
      font-weight: 500;
      font-size: 120%;

      span {
        font-weight: 800;
      }
    }

    .TW-PromoDescription {
      font-weight: 800;
      margin: 1.2em 0;
    }

    .TW-Title,
    .TW-PromoDescription {
      color: $primary;

      @media(min-width: $breakpoint-tablet) {
        font-size: 180%;
      }
    }
  }

  .TW-Banner {
    display: block;

    img {
      max-width: 100%;
    }
  }

  .BannerLoader {
    height: 40vw;
  }

  .TW-Banner,
  .BannerLoader {
    margin-bottom: 1em;
  }
}