@import '../../../../variables';

.MyAccountSectionHeader {
  background-color: $brown;
  border-radius: 5px;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5em 0;

  .Action {
    color: $white;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
}