@import 'src/variables';

.NotFound {
  margin-top: 0;

  .NotFound-top {
    background-color: $dark-black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 0;
    margin-right: 0;
    padding: 50px 30px 50px 15px;

    .Exclamation {
      display: none;
      border-radius: 50%;
      border: 10px solid $brown;
      height: 186px;
      width: 186px;
      text-align: center;

      i {
        font-size: 150px;
        margin-top: 18px;
      }
    }

    i {
      color: $brown;
      font-size: 185px;
      display: none;
    }

    img {
      height: 150px;
      width: 150px;
      background-color: $brown;
    }

    .NotFound-section {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      margin-left: 30px;
      font-size: 30px;

      .Leading {
        color: $brown;
        font-weight: bold;
      }

      .Following {
        color: $gray;
        letter-spacing: 2px;
        opacity: 0.7;
      }
    }
  }

  @media (min-width: $breakpoint-tablet) {
    margin-top: 100px;

    .NotFound-top {
      margin-left: 20px;
      margin-right: 20px;

      .Exclamation {
        display: block;
      }

      i,
      img {
        display: inline-block;
      }

      .NotFound-section {
        font-size: calc(30px + 0.2vw);

        .Leading {
          font-size: calc(30px + 0.9vw);
        }
      }
    }
  }
}
