@import '../../variables';

.BaseSectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize; 
  font-family: $font-family-metropolis;
  margin: 1em 0;
  
  .BaseSectionHeader-container {
    display: flex;
    align-items: center; 
    color: $primary;
    font-size: $fontSizeTitleWeb;
    font-weight: 600;
    letter-spacing: -1px;

    @media (max-width: $breakpoint-mobile) {
      font-size: $fontSizeTitleMobile;
    }
  } 

  .BaseSectionHeader-secondary {
    color: $contrast-dark;
    font-size: 80%;
    white-space: nowrap;
    
    &:hover {
      color: $secondary;
    }
  }
}
