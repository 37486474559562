@import '../../variables.scss';

.DropdownSelect {
  position: relative;
  margin-right: 5px;
  min-width: 5em;

  .DropdownSelectContent {
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    background-color: $primary;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    z-index: 1; 
    color: $white;
    user-select: none; 
    border-radius: 10px;
    text-align: center; 

    &.active {
      display: block;
    }

    .Option {
      padding: 0.3em.8em;
      cursor: pointer;
      font-size: 85%;

      @media (max-width: $breakpoint-mobile) {
        padding: 0.5em;
      }

      &:hover {
        color: $secondary;
      }
    }
  }

  .SelectedTerm {
    margin-right: 5px;
    white-space: nowrap;
  }
}
