@import '../../variables';
 
.SpecOffersHeader { 
  .BaseSectionHeader {
    justify-content: center;
    margin: 2em 0;
    text-align: center;
  }
}

.SpecialOffersContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .BannerSetView {
    width: 100vw;
    max-width: 600px;
    overflow: hidden;
  } 
} 