@import '../../variables';

.BrowseNavigation {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .NavBar-Btn {
    svg {
      width: 0.7em;
    }
  }

  .dropdown-content {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    position: absolute;
    left: 50%;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%);
    transition: 0.3s ease-in-out;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    text-align: center;
    font-size: 90%;
    min-width: 9em;

    .Dropdown-Item {
      border-bottom: 1px solid #c2c6d1;
      padding: 0.5em;
      text-decoration: none;
      text-transform: capitalize;
      color: $primary;
      display: block;
      white-space: nowrap;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        font-weight: 600;
      }
    }
  }

  &:hover {
    .dropdown-content {
      opacity: 1;
      visibility: visible;
    }
  }
}
